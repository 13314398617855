<template>
<Card>
  <div class="num-box">
    <em>10</em>
    <span>人</span>
  </div>
  <div class="tips-box">
    您的企业当前为免费版（成员上限为10人）。若想邀请更多人员加入，请点击立即购买。
  </div>
  <div class="btn-area">
    <div class="btn-buy" @click="handleBuyClick">立即购买</div>
  </div>
</Card>
</template>

<script>
import Card from '@components/Card'

export default {
  name: 'myBill',
  components: {
    Card
  },
  data() {
      return {
        teamId:"",
        projectId : "",
        teamName: "",
        projectName: "",
        corpName: "",
      }
  },
  mounted() {
    this.getOrderInfo();
  },
  methods: {
    handleBuyClick() {
      this.$router.push({name: "createOrder",params:{teamId:this.teamId, teamName:this.teamName, projectId : this.projectId, projectName : this.projectName, corpName: this.corpName}});
    },
    getOrderInfo() {
      this.teamId = this.$route.params.teamId;
      this.teamName = this.$route.params.teamName;
      this.projectId = this.$route.params.projectId;
      this.projectName = this.$route.params.projectName;
      this.corpName = this.$route.params.corpName;
    },
  },
}
</script>
<style lang="stylus">
.order-container
  .num-box
    margin 0 auto 57px
    width 286px
    height 295px
    color #22D9B7
    line-height 295px
    font-size 24px
    font-family sans-serif
    font-weight lighter
    border 1px solid #E9E7E8
    border-radius 4px
    em
      font-size 120px
      font-style initial
      font-weight lighter
  .tips-box
    color #111111
    font-size 22px
    text-align center
  .btn-area
    margin-top 70px
    .btn-buy
      margin 0 auto
      width 230px
      height 50px
      line-height 50px
      font-size 18px
      border-radius 4px
      background #01C8FF
      color #ffffff
      cursor pointer
</style>
